import {
  ConsentRecord,
  ConsentType,
  ConsentLegalText,
  ConsentResponse,
} from "customer-consent-ui/consent-component/src/ts/component";

export const ORIGIN_CUSTOMER_CENTER = "c345b25d-2a50-4171-91e9-338a41ff5d88";

export interface ConsentApiClient {
  fetchConsents(types: ConsentType[]): Promise<ConsentRecord[]>;
  putConsent(header: string, records: ConsentRecord): Promise<any>;
}

export class Client implements ConsentApiClient {
  private readonly url: string;
  constructor() {
    this.url = window.consentApiUrl;
  }

  consentRecordsUrl() {
    return `${this.url}/consent-records`;
  }

  async fetchConsents(types?: ConsentType[]): Promise<ConsentRecord[]> {
    const token = sessionStorage.getItem("account-access-token");
    if (token == null) {
      console.info("Not authorized");
      return;
    }
    let url = this.consentRecordsUrl();
    if (types && types.length > 0) {
      const params = new URLSearchParams();
      for (const type of types) {
        params.append("consentTypes", type);
      }
      url = `${url}?${params.toString()}`;
    }
    const response = await fetch(url, {
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch consents: ${response.status}`);
    }
    const data = (await response.json()) as ConsentResponse[];
    const legalTextIds = data.map(
      (consent) => consent.consentDetails.legalTextId,
    );
    const legalTextResponses = await Promise.all(
      legalTextIds.map((id) =>
        fetch(`${this.url}/legal-texts/${id}`, {
          credentials: "same-origin",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ),
    );
    const legalTextById = {};
    for (const response of legalTextResponses) {
      if (!response.ok) {
        throw new Error(`Failed to fetch legal text: ${response.status}`);
      }
      const legalText = (await response.json()) as ConsentLegalText;
      legalTextById[legalText.id] = legalText.data;
    }
    return data.map((consent) => {
      return {
        ...consent,
        consentDetails: {
          ...consent.consentDetails,
          legalText: legalTextById[consent.consentDetails.legalTextId],
        },
      };
    });
  }

  async putConsent(header: string, record: ConsentRecord): Promise<any> {
    const token = sessionStorage.getItem("account-access-token");
    if (token == null) {
      console.info("Not authorized");
      return;
    }
    const transformedRecords = {};
    transformedRecords[record.consentTypeId] = {
      revision: record.consentTypeRevision,
      accepted: record.state === "ACCEPTED",
      legalTextId: record.consentDetails.legalTextId,
    };
    const body = {
      originId: ORIGIN_CUSTOMER_CENTER,
      createdBy: "CUSTOMER",
      displayedHeadline: header,
      consentRecords: transformedRecords,
    };
    return fetch(this.consentRecordsUrl(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      credentials: "same-origin",
    });
  }
}
